<template>
  <div>
    <b-overlay :show="formShow" rounded="sm" no-fade>
      <form-wizard color="#3178cf" ref="wizard" scope="props" :title="null" :subtitle="null" shape="square" layout="vertical" finish-button-text="Save" back-button-text="Previous" class="vertical-steps steps-transparent" @on-complete="formSubmitted">
        <tab-content title="Description of Vehicle" icon="feather icon-file-text">
          <description-of-vehicle :dropInventorys.sync="dropInventorys" :invInfo.sync="invInfo" :saleQuoteData.sync="saleQuoteData" ref="subDescriptionComponent"></description-of-vehicle>
        </tab-content>

        <tab-content title="Purchaser Identification" icon="feather icon-file-text">
          <purchaser-identification :saleQuoteData.sync="saleQuoteData" :dropCustomers.sync="dropCustomers" :purchaserCustomerData.sync="purchaserCustomerData" ref="subPurchaserComponent"></purchaser-identification>
        </tab-content>

        <tab-content title="Trade-Ins" icon="feather icon-file-text">
          <trade-ins-vehicle :dropInventorys.sync="dropInventorys" :saleQuoteData.sync="saleQuoteData" :dropAll.sync="dropAll" ref="subDescriptionComponent"></trade-ins-vehicle>
        </tab-content>

        <tab-content title="Co-Purchaser" icon="feather icon-file-text">
          <co-purchaser :saleQuoteData.sync="saleQuoteData" :dropCustomers.sync="dropCustomers" :coPurchaserCustomerDatas.sync="coPurchaserCustomerDatas" ref="subcoPurchaserComponent"></co-purchaser>
        </tab-content>

        <tab-content title="Price Calculation" icon="feather icon-file-text">
          <price-calculation :saleQuoteData.sync="saleQuoteData"></price-calculation>
        </tab-content>

        <tab-content title="Comments & Others" icon="feather icon-file-text">
          <comments-others :saleQuoteData.sync="saleQuoteData" ref="subCommentsComponent"></comments-others>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </div>
</template>

<script>
import { BContainer, BOverlay } from 'bootstrap-vue';

import salesStoreModule from '../salesStoreModule';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import { ref, onUnmounted, reactive } from '@vue/composition-api';
import router from '@/router';
import { required, email } from '@validations';
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import DescriptionOfVehicle from './DescriptionOfVehicle.vue';

import PurchaserIdentification from './PurchaserIdentification.vue';
import CoPurchaser from './CoPurchaser.vue';
import PriceCalculation from './PriceCalculation.vue';
import CommentsOthers from './CommentsOthers.vue';
import TradeInsVehicle from './TradeInsVehicle.vue';

export default {
  components: {
    BContainer,
    BOverlay,
    FormWizard,
    TabContent,
    WizardButton,

    required,
    email,

    //TabPage
    DescriptionOfVehicle,
    PurchaserIdentification,
    CoPurchaser,
    PriceCalculation,
    CommentsOthers,
    TradeInsVehicle,
  },
  directives: {
    Ripple,
  },

  setup() {
    const SALES_APP_STORE_MODULE_NAME = 'sales';
    // Register module
    if (!store.hasModule(SALES_APP_STORE_MODULE_NAME)) store.registerModule(SALES_APP_STORE_MODULE_NAME, salesStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SALES_APP_STORE_MODULE_NAME)) store.unregisterModule(SALES_APP_STORE_MODULE_NAME);
    });
  },

  watch: {
    'saleQuoteData.inventoryId': {
      handler: function(x, y) {
        this.getInventoryDropId(x);
      },
    },

    'saleQuoteData.trades': {
      handler: function(x, y) {
        this.saleQuoteData.creditIssued = 0;
        this.saleQuoteData.trades.forEach((element) => {
          this.saleQuoteData.creditIssued += parseInt(element.creditDue);
        });

        this.saleQuoteData.winTrades.forEach((element) => {
          this.saleQuoteData.creditIssued += parseInt(element.creditDue);
        });
      },
    },

    'saleQuoteData.winTrades': {
      handler: function(x, y) {
        this.saleQuoteData.creditIssued = 0;
        this.saleQuoteData.winTrades.forEach((element) => {
          this.saleQuoteData.creditIssued += parseInt(element.creditDue);
        });

        this.saleQuoteData.trades.forEach((element) => {
          this.saleQuoteData.creditIssued += parseInt(element.creditDue);
        });
      },
    },
  },

  mounted() {
    var wizard = this.$refs.wizard;
    wizard.activateAll();
  },

  created() {
    this.getInventoryDrops();
    this.getCustomerDrops();
    // this.getPurchaseDrops();
    this.getByIdQuote();
  },

  data() {
    var saleQuoteDataModel = {
      inventoryId: null,
      trades: [],
      winTrades: [],

      purchaserIdentification: {
        sellerType: null,
        phoneNumber: null,
        firstName: null,
        lastName: null,
        email: null,
        unitNumber: null,
        provence: null,
        streetAddress: null,
        city: null,
        postalCode: null,
        country: null,
        gst: null,
        pst: null,
        dealer: null,
        driverLicenseNumber: null,
        otherIdNumber: null,
        dateOfBirth: null,
        contactPerson: null,
        contactInfo: null,
      },
      coPurchaser: [],
      priceContractType: null,
      saleType: null,
      listingPrice: null,
      sellingPrice: null,
      balanceDue: null,
      balanceAfter: null,
      gstRateVehicle: null,
      creditIssued: null,
      payableToCustomerValue: null,
      quoteNumber: null,
      completionDate: null,
      quoteDate: null,
      location: null,
      comments: null,
      discountRate: null,
      deductionCredit: null,
      lessDepositPayable: null,
      lessAdditionalCredit: null,
      priceCalculationValues: [],
      paymentBalanceTotal: null,
      dueOnDelivery: null,
      gstAmount: null,
      pstAmount: null,
      lessTradeAllowance: null,
      customerTradeEquity: null,
      priceDifference: null,
      totalSaleAmount: null,
      optionsSubTotal: null,
      optionsGst: null,
      optionsPst: null,
      optionsTotal: null,
      optionAmountTotal: null,
      lienPayoutTrades: null,
      totalContractAmount: null,
    };

    var wherefrom = router.currentRoute.name;
    return {
      invInfo: {},
      wherefrom,
      formShow: false,
      baseURL: store.state.app.baseURL,
      saleQuoteData: saleQuoteDataModel,
      dropAll: [],

      purchaserCustomerData: {},
      coPurchaserCustomerDatas: [],

      dropInventorys: [],
      dropCustomers: [],
    };
  },

  methods: {
    isEmptyOrSpaces(str) {
      return str === null || str.match(/^ *$/) !== null;
    },
    isZero(num) {
      if (num == 0) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    formSubmitted() {
      if (this.saleQuoteData.inventoryId > 0) {
        var calculationsOk = false;

        this.$refs.subPurchaserComponent.$refs.purchaserRules.validate().then((success) => {
          if (success) {
            this.$refs.subCommentsComponent.$refs.commentsRules.validate().then((success) => {
              if (success) {
                //CO-OWNER
                var saveOk = 0;

                this.saleQuoteData.coPurchaser.forEach((element) => {
                  if ((this.isEmptyOrSpaces(element.sellerType) && this.isEmptyOrSpaces(element.firstName)) || (!this.isEmptyOrSpaces(element.sellerType) && !this.isEmptyOrSpaces(element.firstName))) {
                    saveOk += 1;
                  } else {
                    this.$refs.subcoPurchaserComponent.$refs.coPurchaserRules.validate().then((success) => {
                      if (success) {
                      } else {
                        this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 5);
                      }
                    });
                  }
                });

                if (this.saleQuoteData.sellingPrice == 0 || this.saleQuoteData.sellingPrice == null) {
                  this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 4);
                  this.$swal({
                    text: 'The selling price must be greater than 0.',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                  });
                  calculationsOk = false;
                } else {
                  calculationsOk = true;
                }

                if (saveOk == this.saleQuoteData.coPurchaser.length && calculationsOk == true) {
                  this.saveOrUpdateQuote();
                }
                //CO-OWNER
              } else {
                this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 5);
              }
            });
          } else {
            this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 1);
          }
        });
      } else {
        this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0);
      }
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    saveOrUpdateQuote() {
      this.formShow = true;

      //new add before excel import
      var tempCustomerTrade = Number(this.saleQuoteData.creditIssued - this.saleQuoteData.sellingPrice - this.saleQuoteData.deductionCredit);
      if (tempCustomerTrade > 0) {
        this.saleQuoteData.gstRateVehicle = 0;
        this.saleQuoteData.pstRateVehicle = 0;
      } else {
        this.saleQuoteData.balanceAfter = 0;
      }
      //finished

      if (router.currentRoute.params.id) {
        store
          .dispatch('sales/updateQuote', this.saleQuoteData)
          .then((response) => {
            if (response.data != 'alreadyUpdated') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Quote Update Successful',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });

              setTimeout(() => {
                //orhan

                router.push({ name: 'sales-preview', params: { id: router.currentRoute.params.id } });

                this.formShow = false;
              }, 1000);
            } else {
              this.formShow = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Please refresh the page.',
                  text: '⚠️ Data has been changed during operations. ',
                  icon: 'RefreshCwIcon',
                  variant: 'warning',
                },
              });
            }
          })
          .catch((error) => {
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            router.push({ name: 'sales-list' });
          });
      } else {
        store
          .dispatch('sales/saveQuote', this.saleQuoteData)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Sale Quote has been created successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            setTimeout(function() {
              router.push({ name: 'sales-preview', params: { id: response.data.id } });
              this.formShow = false;
            }, 1000);
          })
          .catch((error) => {
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            //       router.push({ name: 'sales-list' });
          });
      }
    },

    quoteNumberCreate() {
      store
        .dispatch('sales/nextIdQuotes')
        .then((response) => {
          this.saleQuoteData.quoteNumber = Number(response.data);
          this.formShow = false;
        })
        .catch((error) => {});
    },

    getByIdQuote() {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        store
          .dispatch('sales/getQuoteById', { id: router.currentRoute.params.id })
          .then((response) => {
            this.saleQuoteData.id = response.data.id;

            this.saleQuoteData.inventoryId = response.data.inventoryId;
            this.saleQuoteData.location = response.data.location;
            this.saleQuoteData.comments = response.data.comments;
            this.saleQuoteData.quoteDate = response.data.quoteDate;
            this.saleQuoteData.completionDate = response.data.completionDate;
            this.saleQuoteData.quoteNumber = response.data.id;
            this.purchaserCustomerData = response.data.get_seller_customer;
            this.saleQuoteData.priceContractType = response.data.priceContractType;
            this.saleQuoteData.saleType = response.data.saleType;
            this.saleQuoteData.discountRate = response.data.discountRate;
            this.saleQuoteData.deductionCredit = response.data.deductionCredit;
            this.saleQuoteData.lessDepositPayable = response.data.lessDepositPayable;
            this.saleQuoteData.lessAdditionalCredit = response.data.lessAdditionalCredit;
            this.saleQuoteData.sellingPrice = response.data.sellingPrice;
            this.saleQuoteData.gstRateVehicle = response.data.gstRateVehicle;
            this.saleQuoteData.pstRateVehicle = response.data.pstRateVehicle;
            this.saleQuoteData.balanceAfter = response.data.payTradeCredit;
            this.saleQuoteData.updated_at = response.data.updated_at;
            this.saleQuoteData.paymentBalanceTotal = response.data.paymentBalanceTotal;

            this.saleQuoteData.gstAmount = response.data.gstAmount;
            this.saleQuoteData.pstAmount = response.data.pstAmount;
            this.saleQuoteData.lessTradeAllowance = response.data.lessTradeAllowance;
            this.saleQuoteData.customerTradeEquity = response.data.customerTradeEquity;
            this.saleQuoteData.priceDifference = response.data.priceDifference;
            this.saleQuoteData.totalSaleAmount = response.data.totalSaleAmount;
            this.saleQuoteData.optionsSubTotal = response.data.optionsSubTotal;
            this.saleQuoteData.optionsGst = response.data.optionsGst;
            this.saleQuoteData.optionsPst = response.data.optionsPst;
            this.saleQuoteData.optionsTotal = response.data.optionsTotal;
            this.saleQuoteData.optionAmountTotal = response.data.optionAmountTotal;
            this.saleQuoteData.lienPayoutTrades = response.data.lienPayoutTrades;
            this.saleQuoteData.totalContractAmount = response.data.totalContractAmount;
            this.saleQuoteData.dueOnDelivery = response.data.dueOnDelivery;
            response.data.coOwnerList.forEach((element) => {
              this.coPurchaserCustomerDatas.push(element);
            });

            //GetAdditional
            this.saleQuoteData.priceCalculationValues = [];
            response.data.get_additional.forEach((element) => {
              this.saleQuoteData.priceCalculationValues.push({
                pcOptions: element.title,
                pcPrice: element.price,
                pcGst: element.gst,
                pcPst: element.pst,
                pcGstAmount: (element.price / 100) * element.gst,
                pcPstAmount: (element.price / 100) * element.pst,
                pcSubTotal: Number(element.price) + (element.price / 100) * Number(element.gst) + (element.price / 100) * element.pst,
              });
            });

            var tempTrades = {};
            var tempWinTrades = {};

            response.data.trades.forEach((data) => {
              tempTrades.id = data.id;
              tempTrades.inventoryId = data.inventoryId;
              tempTrades.vehicleImages = data.get_inventory.get_vehicle_images[0];

              tempTrades.vehicleId = data.inventoryId;
              tempTrades.condition = data.get_inventory.condition.charAt(0).toUpperCase() + data.get_inventory.condition.slice(1);
              tempTrades.mileageUnit = data.get_inventory.mileageUnit;
              tempTrades.extColour = data.get_inventory.extColour;
              tempTrades.modelYear = data.get_inventory.modelYear;
              tempTrades.mileageValue = data.get_inventory.mileageValue;
              tempTrades.intColour = data.get_inventory.intColour;
              tempTrades.make = data.get_inventory.make;
              tempTrades.body = data.get_inventory.body;
              tempTrades.fuel = data.get_inventory.fuel;
              tempTrades.model = data.get_inventory.model;
              tempTrades.drivetrain = data.get_inventory.drivetrain;
              tempTrades.trim = data.get_inventory.trim;
              tempTrades.transmmission = data.get_inventory.transmmission;
              tempTrades.vinNo = data.get_inventory.vinNo;
              tempTrades.stockNumber = data.get_inventory.stockNumber;
              tempTrades.seatingCapacity = data.get_inventory.seatingCapacity;
              tempTrades.numberOfDoors = data.get_inventory.numberOfDoors;

              tempTrades.quoteGallery = data.get_images ? data.get_images : [];

              tempTrades.origenalMarket = data.get_inventory.origenalMarket;
              tempTrades.baseMsrp = data.get_inventory.baseMsrp;
              tempTrades.msrpCurrency = data.get_inventory.msrpCurrency;
              tempTrades.msrpOptions = data.get_inventory.get_msrp_options ? data.get_inventory.get_msrp_options : [];

              tempTrades.accessioreOptions = data.get_inventory.get_accessiore_options ? data.get_inventory.get_accessiore_options : [];
              tempTrades.vehicleDeclearation = [data.get_inventory.vd1, data.get_inventory.vd2, data.get_inventory.vd3, data.get_inventory.vd4, data.get_inventory.vd5, data.get_inventory.vd6, data.get_inventory.vd7];
              tempTrades.vehicleDeclearationText = data.get_inventory.vd1Text;

              tempTrades.vehicleTitleType = data.vehicleTitleType;

              tempTrades.appraisedVehiclePrice = data.appraisedVehiclePrice;
              tempTrades.creditDue = data.appraisedVehiclePrice;
              tempTrades.appraisalNotes = data.appraisalNotes;
              tempTrades.payoutType = data.payoutType;
              tempTrades.beforeTax = data.beforeTax * -1;

              tempTrades.unitlDate = data.unitlDate;
              tempTrades.gstRate = data.gstRate;

              //////////////////
              // tempTrades.priceDifference = 0;
              // tempTrades.gstAmountOnVehicle = 0;
              // tempTrades.optionAmountTotal = 0;

              // tempTrades.contractAmount= tempTrades.gstAmountOnVehicle; /////////// ????;
              // if((data.beforeTax ? data.beforeTax :0)>tempTrades.contractAmount){
              //   tempTrades.payableBySeller = (tempTrades.contractAmount - (data.beforeTax ? data.beforeTax :0)) * -1;

              // }
              // else{
              //   tempTrades.payableBySeller= 0;

              // }
              // tempTrades.balanceDueDelivery = tempTrades.contractAmount - (data.beforeTax ? data.beforeTax :0),

              // tempTrades.gstAmount=data.gstRate * data.beforeTax / 100,
              // tempTrades.totalLienPayout = (data.gstRate * data.beforeTax / 100) +  data.beforeTax,
              ////////////////////////

              tempTrades.lienCustomerId = data.lienCustomerId ? data.lienCustomerId : null;
              tempTrades.lienHolder = {
                sellerType: data.get_lien_customer ? data.get_lien_customer.sellerType : null,
                phoneNumber: data.get_lien_customer ? data.get_lien_customer.phoneNumber : null,
                firstName: data.get_lien_customer ? data.get_lien_customer.firstName : null,
                lastName: data.get_lien_customer ? data.get_lien_customer.lastName : null,
                email: data.get_lien_customer ? data.get_lien_customer.email : null,
                unitNumber: data.get_lien_customer ? data.get_lien_customer.unitNumber : null,
                provence: data.get_lien_customer ? data.get_lien_customer.provence : null,
                streetAddress: data.get_lien_customer ? data.get_lien_customer.streetAddress : null,
                city: data.get_lien_customer ? data.get_lien_customer.city : null,
                postalCode: data.get_lien_customer ? data.get_lien_customer.postalCode : null,
                country: data.get_lien_customer ? data.get_lien_customer.country : null,
                gst: data.get_lien_customer ? data.get_lien_customer.gst : null,
                pst: data.get_lien_customer ? data.get_lien_customer.pst : null,
                dealer: data.get_lien_customer ? data.get_lien_customer.dealer : null,
                driverLicenseNumber: data.get_lien_customer ? data.get_lien_customer.driverLicenseNumber : null,
                otherIdNumber: data.get_lien_customer ? data.get_lien_customer.otherIdNumber : null,
                dateOfBirth: data.get_lien_customer ? data.get_lien_customer.dateOfBirth : null,
              };

              this.saleQuoteData.trades.push(tempTrades);
              tempTrades = {};
            });

            response.data.winTrades.forEach((data) => {
              tempWinTrades.id = data.id;
              tempWinTrades.appraisedVehiclePrice = data.appraisedVehiclePrice;
              tempWinTrades.creditDue = data.creditDue;
              tempWinTrades.beforeTax = data.beforeTax;
              tempWinTrades.checked = false;
              tempWinTrades.value = '[ ' + Number(data.id) + ' ] ' + data.inventoryId + ' ' + data.get_inventory.get_model_year.value + ' ' + data.get_inventory.get_make.value + ' ' + data.get_inventory.get_model.value;

              //"[ 100153 ] 100108 - 1997 LINCOLN TEST"

              this.saleQuoteData.winTrades.push(tempWinTrades);
              tempWinTrades = {};
            });

            //trades finish

            setTimeout(() => (this.formShow = false), 1000);
          })
          .catch((error) => {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            router.push({ name: 'sales-list' });
          });
      } else {
        this.quoteNumberCreate();
      }
    },

    getInventoryDropId(id, quoteId) {
      this.formShow = true;
      if (id != null) {
        store
          .dispatch('sales/getInventoryDropDownId', id)
          .then((response) => {
            this.invInfo = response.data;
            this.saleQuoteData.listingPrice = response.data.listingPrice;
            this.formShow = false;
            // }
          })
          .catch((error) => {
            console.log(error);
            router.push({ name: 'sales-list' });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching inventory',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        //CLEAR INVENTORY MODEL

        this.saleQuoteData.payoutType = null;
        this.saleQuoteData.beforeTax = null;
        this.saleQuoteData.unitlDate = null;
        this.saleQuoteData.gstRate = null;
        this.saleQuoteData.payoutTax = null;
        this.saleQuoteData.goodUnitlDate = null;
        this.saleQuoteData.payoutGstRate = null;
        this.saleQuoteData.payoutGstAmount = null;
        this.saleQuoteData.payoutTotalAmount = null;
        this.saleQuoteData.inventoryGallery = [];
        this.saleQuoteData.deleteImages = [];
        this.saleQuoteData.priceContractType = null;
        this.saleQuoteData.saleType = null;

        this.saleQuoteData.sellingPrice = null;
        this.saleQuoteData.balanceDue = null;
        this.saleQuoteData.balanceAfter = null;
        this.saleQuoteData.discountRate = null;

        this.saleQuoteData.deductionCredit = null;
        this.saleQuoteData.lessDepositPayable = null;
        this.saleQuoteData.lessAdditionalCredit = null;

        this.saleQuoteData.gstRateVehicle = null;
        this.saleQuoteData.pstRateVehicle = null;
        this.saleQuoteData.creditDue = null;
        this.saleQuoteData.priceCalculationValues = [];
        this.formShow = false;
      }
    },

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },

    getInventoryDrops() {
      store
        .dispatch('sales/getInventoryDropDowns')
        .then((response) => {
          response.data.forEach((element) => {
            if (element.value != null) {
              this.dropInventorys.push(element);
            }
          });
        })
        .catch((error) => {});
    },

    getPurchaseDrops() {
      store
        .dispatch('sales/getPurchaseDropDowns')
        .then((response) => {
          response.data.forEach((element) => {
            if (element.value != null) {
              //  this.dropInventorys.push(element);
            }
          });
        })
        .catch((error) => {});
    },

    getCustomerDrops() {
      store
        .dispatch('sales/getCustomerDropDowns')
        .then((response) => {
          this.dropCustomers = response.data;
        })
        .catch((error) => {});
    },

    validationForm1() {
      return new Promise((resolve, reject) => {
        this.$refs.form1.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.add-new-dropdown {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
    cursor: pointer;
  }
}
</style>
